<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">
          {{ $t("customer_information.information_content.edit_title") }} <br />
        </h2>
        <b-button
          variant="outline-primary"
          @click="$router.push({ name: 'contenu-informationnel-list' })"
          class="d-flex align-items-center"
        >
          <i class="las la-arrow-left mr-50"></i>
          <span>{{ $t("button.back_to_list") }}</span>
        </b-button>
      </div>
    </b-card>

    <b-card>
      <validation-observer ref="formRules">
        <b-row>
          <b-col md="6">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_title'
                )
              "
              label-cols-md="12"
              label-for="title"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_title"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="title"
                  v-model="form.title"
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_title_placeholder'
                    )
                  "
                  type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <!-- listTypePosts = {{ listTypePosts }} -->
            <b-form-group
              :label="
                $t('customer_information.information_content.create_input_type')
              "
              label-cols-md="12"
              label-for="categories"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_type"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <v-select
                id="categories"
                v-model="form.type"
                :placeholder="
                  $t(
                    'customer_information.information_content.create_input_type_placeholder'
                  )
                "
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="label"
                :reduce="(option) => option.value"
                :options="listTypePosts"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_sector'
                )
              "
              label-cols-md="12"
              label-for="secteur"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_sector"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <v-select
                id="roles"
                v-model="form.secteur_id"
                :placeholder="
                  $t(
                    'customer_information.information_content.create_input_sector_placeholder'
                  )
                "
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :reduce="(option) => option.id"
                :options="listSecteurs"
              /> </b-form-group
          ></b-col>
          <b-col md="6">
            <b-form-group
              :label="
                $t('customer_information.information_content.create_input_tags')
              "
              label-cols-md="12"
              label-for="cover"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_tags"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-tags
                  :add-button-text="$t('button.add')"
                  id="tags"
                  type="text"
                  v-model="form.tags"
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_tags_placeholder'
                    )
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider></b-form-group
            >
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_cover'
                )
              "
              label-cols-md="12"
              label-for="cover"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_cover"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-file
                  id="cover"
                  accept="image/*"
                  v-if="cover_preview == null"
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_cover_placeholder'
                    )
                  "
                  drop-placeholder="Déposer le fichier ici..."
                  v-model="form.cover"
                ></b-form-file>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-col
              class="image-container"
              v-if="form && form.cover == null && cover_preview != null"
            >
              <img :src="cover_preview" alt="cover" width="350px" />
              <div class="overlay">
                <b-button
                  variant="danger"
                  class="delete-btn"
                  @click="removeCover"
                  >{{ $t("button.remove") }}</b-button
                >
              </div>
            </b-col>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_status'
                )
              "
              label-cols-md="12"
              label-for="cover"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_status"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <v-select
                  id="status"
                  v-model="form.status"
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_status_placeholder'
                    )
                  "
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="label"
                  :reduce="(option) => option.value"
                  :options="listPostsStatus"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col md="12">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_description'
                )
              "
              label-cols-md="12"
              label-for="description"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_description"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-textarea
                  v-model="form.short_description"
                  class="mb-2 mb-lg-0"
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_description_placeholder'
                    )
                  "
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12" v-if="form.type == 'concours'">
            <b-form-group
              :label="
                $t('customer_information.information_content.create_input_url')
              "
              label-cols-md="12"
              label-for="title"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_url"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <b-form-input
                  id="title"
                  v-model="form.url"
                  @input="validateUrl"
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_url_placeholder'
                    )
                  "
                  type="url"
                />

                <small class="text-danger">
                  {{
                    isValidUrl == false
                      ? "Veuillez renseigner une url valide"
                      : errors[0]
                  }}
                </small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_content'
                )
              "
              label-cols-md="12"
              label-for="description"
            >
              <template v-slot:label>
                <span
                  >{{
                    $t(
                      "customer_information.information_content.create_input_content"
                    )
                  }}
                  <RequiredStar />
                </span>
              </template>
              <validation-provider v-slot="{ errors }" rules="required">
                <vue-editor
                  v-model="form.description"
                  class=""
                  :placeholder="
                    $t(
                      'customer_information.information_content.create_input_content_placeholder'
                    )
                  "
                  :editor-toolbar="customToolbar"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="12">
            <b-form-group
              :label="
                $t(
                  'customer_information.information_content.create_input_attaches_files'
                )
              "
              label-cols-md="12"
              label-for="cover"
            >
              <b-form-file
                multiple
                id="attached_file"
                v-if="modify == true"
                :placeholder="
                  $t(
                    'customer_information.information_content.create_input_cover_placeholder'
                  )
                "
                drop-placeholder="Déposer le(s) fichier(s) ici..."
                v-model="form.attached_files"
              ></b-form-file>
              <div class="mt-1" v-if="modify == false">
                <ul
                  v-if="form.attached_files && form.attached_files.length != 0"
                >
                  <li
                    v-for="(file, indexfile) in form.attached_files"
                    :key="indexfile"
                  >
                    {{ file.file_name ? file.file_name : file.name }}
                    <b-button
                      v-b-tooltip.hover.top="'Enlever ce fichier'"
                      variant="flat-danger"
                      class="btn-icon p-0"
                      @click="removeFile(indexfile, file.id)"
                    >
                      <i class="las la-times-circle" />
                    </b-button>
                  </li>
                </ul>

                <p class="px-1" v-else>
                  {{
                    $t(
                      "customer_information.information_content.detail_empty_attached_files"
                    )
                  }}
                </p>

                <b-col cols="12">
                  <b-button variant="secondary" @click="modifyFiles">{{
                    $t("button.add")
                  }}</b-button>
                </b-col>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="text-right">
          <b-button
            variant="primary"
            @click="updatePost()"
            :disabled="isCreatingPost"
          >
            <feather-icon icon="SaveIcon" v-if="!isCreatingPost" /><b-spinner
              v-else
              small
            />
            &nbsp;
            {{
              $t("customer_information.information_content.edit_form_button")
            }}
          </b-button>
        </div>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormInput,
  BFormTags,
  BFormFile,
  BSpinner,
  BCardHeader,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueEditor } from "vue2-editor";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import PostsStoreModule from "@/store/blog";
import secteursStoreModule from "@/store/secteurs";
import RequiredStar from "@/components/RequiredStar.vue";

import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTags,
    BFormFile,
    BFormTextarea,
    VueEditor,
    vSelect,
    BSpinner,
    BCardHeader,
    ValidationProvider,
    ValidationObserver,
    RequiredStar,
  },
  setup() {
    const requiredStoreModules = [
      { path: "posts", module: PostsStoreModule },
      { path: "secteurs", module: secteursStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    return {
      requiredStoreModules,
    };
  },

  data() {
    return {
      configs: [
        {
          id: null,
          code: "concours.configuration.enable",
          value: false,
        },
      ],
      loadingUpdateConfig: false,
      form: {
        concours: "",
      },

      listTypePosts: [],
      listPostsStatus: [],
      listSecteurs: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["image", "link"],
      ],
      form: {
        title: null,
        post_category_id: null,
        description: null,
        tags: null,
        attached_files: null,
        cover: null,
        status: null,
        short_description: null,
        secteur_id: null,
        url: null,
      },
      medias_to_delete_ids: [],
      attached_files: null,
      cover_preview: null,
      isCreatingPost: false,
      isFetchingSecteur: false,
      isValidUrl: false,
      modify: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        this.loadDataAppConfiguration();
        this.loadPost();
        this.fetchPostsTypes();
        this.fetchPostsStatus();
        this.fetchSecteurs();
      },
    },
    attached_files() {
      console.log("atached_files", this.form.attached_files);
      this.form.attached_files.push(...this.form.attached_files);
      return this.form.attached_files;
    },
  },
  computed: {},
  // beforeDestroy() {
  //   unregisterStoreModule(this.requiredStoreModules)
  // },
  mounted() {},
  methods: {
    // import actions
    ...mapActions("app-configurations", {
      action_getAppConfiguration: "getAppConfiguration",
      action_searchAppConfiguration: "searchAppConfiguration",
      action_updateAppConfiguration: "updateAppConfiguration",
    }),
    ...mapActions("posts", {
      action_fetchPostsStatus: "fetchPostsStatus",
      action_fetchPostsTypes: "fetchPostsTypes",
      action_addPosts: "addPosts",
      action_getPosts: "getPosts",
      action_updatePosts: "updatePosts",
    }),
    ...mapActions("secteurs", {
      action_fetchSecteurs: "fetchSecteurs",
    }),

    // -----------------------------------------------------------------------
    async loadDataAppConfiguration() {
      this.loadingUpdateConfig = true;
      this.configs.forEach(async (config, index) => {
        this.action_searchAppConfiguration({ code: config.code })
          .then((response) => {
            if (response.status === 200) {
              this.configs[index].value = response.data.data[0].value;
              this.configs[index].id = response.data.data[0].id;

              if (index === 0) {
                this.form.concours = this.configs[index].value;
              }
            }
            this.loadingUpdateConfig = false;
          })
          .catch((err) => {
            this.loadingUpdateConfig = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: err.message || "Une erreur est survenue",
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          });
      });
    },
    removeFile(index, id) {
      console.log("indexfiles", index);
      this.form.attached_files.splice(index, 1);
      this.medias_to_delete_ids.push(id);
      console.log("mediatoremoveids", this.medias_to_delete_ids);
    },
    removeCover() {
      (this.form.cover = null), (this.cover_preview = null);
    },
    handleFileChange() {
      this.form.attached_files.push(this.attached_files);
      console.log(
        "this.attachedfi",
        this.attached_files,
        this.form.attached_files
      );
    },
    modifyFiles() {
      this.modify = true;
    },
    fetchPostsTypes() {
      this.isFetchingSecteur = true;
      this.action_fetchPostsTypes()
        .then((response) => {
          this.isFetchingSecteur = false;
          response.data.map((e) => {
            console.log("this.status: ", e);

            if (e == "blog") {
              const status = {
                value: "blog",
                label: "Contenu Informationnel",
              };
              this.listTypePosts.push(status);
            }
            if (e == "stats") {
              const status = {
                value: "stats",
                label: "Chiffres et Tendances",
              };
              this.listTypePosts.push(status);
            }
            if (e == "concours" && this.form.concours == true) {
              const status = {
                value: "concours",
                label: "Concours",
              };
              this.listTypePosts.push(status);
            }
            if (e == "rules") {
              const status = {
                value: "rules",
                label: "Règlementations",
              };
              this.listTypePosts.push(status);
            }
            if (e == "investors") {
              const status = {
                value: "investors",
                label: "Investisseurs",
              };
              this.listTypePosts.push(status);
            }
          });

          console.log("this.categories: ", this.listTypePosts);
        })
        .catch((error) => {
          this.isFetchingSecteur = false;
        });
    },
    validateUrl() {
      // Regular expression for a simple URL validation
      const urlRegex = /^(http|https):\/\/[^ "]+$/;

      // Test if the entered URL matches the regex
      this.isValidUrl = urlRegex.test(this.form.url);
      // return this.isValidUrl
    },
    fetchPostsStatus() {
      this.isFetchingSecteur = true;
      this.action_fetchPostsStatus()
        .then((response) => {
          console.log("this.status: ", response);

          this.isFetchingSecteur = false;
          response.data.map((e) => {
            console.log("this.status: ", e);

            if (e == "drafted") {
              const status = {
                value: "drafted",
                label: "Brouillon",
              };
              this.listPostsStatus.push(status);
            }
            if (e == "published") {
              const status = {
                value: "published",
                label: "Publié",
              };
              this.listPostsStatus.push(status);
            }
          });
          console.log("this.status: ", this.listPostsStatus);
        })
        .catch((error) => {
          this.isFetchingSecteur = false;
        });
    },
    fetchSecteurs() {
      this.isFetchingSecteur = true;
      this.action_fetchSecteurs()
        .then((response) => {
          console.log("this.secteur: ", response);
          this.isFetchingSecteur = false;
          this.listSecteurs = response.data.data;
          // console.log('this.formload11111: ', this.listSecteurs)
        })
        .catch((error) => {
          this.isFetchingSecteur = false;
        });
    },

    loadPost() {
      this.isCreatingProduct = true;
      this.action_getPosts(this.$route.params.id)
        .then((response) => {
          this.isCreatingProduct = false;
          this.form = {
            ...response.data.data,
            url: response.data.data.link,
            cover: null,
          };
          this.cover_preview = response.data.data.cover;
          console.log("this.formload: ", this.form);
        })
        .catch((error) => {
          // console.log(error)
          this.isCreatingProduct = false;
          // this.errored = true
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Une erreur est survenue",
              icon: "CoffeeIcon",
              variant: "danger",
              text: "",
            },
          });
        });
    },

    updatePost() {
      this.$refs.formRules
        .validate()
        .then((success) => {
          if (success == true) {
            this.isCreatingPost = true;
            const formData = new FormData();
            formData.append("title", this.form.title);
            formData.append("description", this.form.description);
            formData.append("short_description", this.form.short_description);
            this.form.tags.forEach((tag) => {
              formData.append("tags[]", tag);
            });
            formData.append("type", this.form.type);
            formData.append("secteur_id", this.form.secteur_id);
            formData.append("status", this.form.status);
            formData.append("link", this.form.url);

            if (this.cover_preview == null) {
              formData.append("cover", this.form.cover);
            }
            if (this.modify == true) {
              this.form.attached_files.forEach((piece) => {
                console.log("pieces", piece);
                formData.append("attached_files[]", piece);
              });
            }
            formData.append(
              "medias_to_delete_ids[]",
              this.medias_to_delete_ids
            );
            formData.append("_method", "PUT");
            this.currentId = this.$route.params.id;
            console.log("this.formupdate: ", this.form);
            this.action_updatePosts({
              id: this.currentId,
              data: formData,
            })
              .then((response) => {
                this.isCreatingPost = false;
                this.currentId = null;
                this.$router
                  .replace({ name: "contenu-informationnel-list" })
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: "Création réussie",
                        icon: "CoffeeIcon",
                        variant: "success",
                        text: "",
                      },
                    });
                  });
              })
              .catch((error) => {
                // console.log(error)
                this.isCreatingPost = false;
                // this.errored = true
                this.$toast({
                  component: ToastificationContent,
                  position: "top-right",
                  props: {
                    title: "Une erreur est survenue",
                    icon: "CoffeeIcon",
                    variant: "danger",
                    text: "",
                  },
                });
              });
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style lang="scss" scoped>
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}

img {
  aspect-ratio: 16/6;
  overflow: hidden;
  border-radius: 5px;
  object-fit: cover;
}

.image-container {
  position: relative;
  width: 350px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 1;
  width: 350px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.318);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.delete-btn {
  display: none;
}

.image-container:hover .overlay {
  display: flex;
}

.image-container:hover .delete-btn {
  display: block;
}
</style>
